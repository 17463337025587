import LogoImage from '../../assets/airdolomiti_logo.svg';
import './styles.scss';

const Logo = () => (
  <a className="logo-wrapper" href="https://www.airdolomiti.eu/">
    <img className="logo" src={LogoImage} alt="Air Dolomiti logo" />
  </a>
);

export default Logo;
